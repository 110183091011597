import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Icon } from "@iconify/react";

import SharpeLogoWhite from "../images/sharpe-logo-white.svg";
import SharpeLogoBlack from "../images/sharpe-logo-black.svg";
import numeral from "numeral";

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./ui/tooltip";
import { Button } from "./ui/button";
import { getSharpePointsForUser } from "../firebase/config";
import ShimmerButton from "./magicui/shimmer-button";

// const CustomTooltip = styled(({ className, ...props }) => (
//   <Tooltip {...props} classes={{ popper: className }} />
// ))(({ theme, mode }) => ({
//   [`& .${tooltipClasses.tooltip}`]: {
//     color: mode === "light" ? "#000" : "#98ECFF",
//     border: "1px solid #98ECFF",
//     background: mode === "light" ? "#98ECFF" : "transparent",
//     padding: "8px 16px",
//     fontSize: "14px",
//     cursor: "default",
//     fontFamily: "Inter, sans-serif",
//     "&:hover": {
//       border: "1px solid #98ECFF",
//     },
//     textTransform: "capitalize",
//     borderRadius: "0",
//   },
//   [`& .${tooltipClasses.arrow}`]: {
//     color: mode === "light" ? "#98ECFF" : "#98ECFF",
//   },
// }));

export default function Header({ onModeChange }) {
  const [mode, setMode] = useState("dark");
  // const [sharpePoints, setSharpePoints] = useState(0);

  console.log("mode", mode);
  // Function to toggle mode
  const toggleMode = () => {
    const newMode = mode === "light" ? "dark" : "light";
    setMode(newMode);
    onModeChange(newMode);
  };

  // useEffect(() => {
  //   const fetchSharpePoints = async () => {
  //     try {
  //       const points = await getSharpePointsForUser(walletAddress);
  //       setSharpePoints(points);
  //     } catch (error) {
  //       console.error("Error fetching Sharpe points:", error);
  //     }
  //   };

  //   if (walletAddress) {
  //     fetchSharpePoints();
  //   }
  // }, [walletAddress]);

  const renderIcon = () => {
    if (mode === "light") {
      return (
        <Icon
          icon="tdesign:mode-dark"
          width="22"
          height="22"
          style={{ color: "rgb(74 74 74)" }}
        />
      );
    } else {
      return (
        <Icon
          icon="iconamoon:mode-light"
          width="22"
          height="22"
          style={{ color: "#cfcfcf" }}
        />
      );
    }
  };
  const icon = renderIcon();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        margin: "4px 0px",
      }}
    >
      <a href="/" style={{ textDecoration: "none", margin: "10px 20px" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginTop: "5px",
          }}
        >
          <img
            height={28}
            width={93}
            src={mode === "light" ? SharpeLogoBlack : SharpeLogoWhite}
            alt="profile"
          />
        </Box>
      </a>

      <div style={{ display: "flex", gap: "8px", margin: "10px 20px" }}>
        <div className="z-10 flex items-center justify-center">
          <ShimmerButton
            className="shadow-2xl"
            onClick={() => window.open("https://stake.sharpe.ai/", "_blank")}
            mode={mode} // Pass the current mode to ShimmerButton
          >
            <span
              className={`whitespace-pre-wrap text-center py-0 text-sm font-medium leading-none tracking-tight lg:text-[14px] ${
                mode === "light" ? "text-black" : "text-white"
              }`}
            >
              Earn $SAI
            </span>
          </ShimmerButton>
        </div>
        <TooltipProvider delayDuration={200}>
          <Tooltip>
            <TooltipTrigger asChild className="bg-transparent rounded-md">
              <Button
                variant="outline"
                size="icon"
                className={`
        bg-transparent 
        rounded-[6px] 
        h-[44px]
        w-[44px]
        hover:bg-transparent 
        ${mode === "dark" ? "border-neutral-700" : "border-neutral-400"} 
        ${mode === "dark" ? "dark:bg-black" : ""}
      `}
                onClick={toggleMode}
              >
                {icon}
              </Button>
            </TooltipTrigger>
            <TooltipContent
              className={`border-neutral-800 rounded-[4px] bg-transparent ${
                mode === "dark" ? "border-neutral-800" : "border-neutral-200"
              } ${mode === "dark" ? "text-neutral-200" : "text-black"}`}
            >
              <p
                className={`text-[12px]
               ${mode === "dark" ? "text-white" : "text-black"},`}
              >
                Switch to {mode === "light" ? "dark" : "light"} mode
              </p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
        {/* <Box sx={{ display: "flex", gap: "0px" }}>
          <TooltipProvider delayDuration={200}>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant="outline"
                  size="icon"
                  className={`
        bg-transparent 
        rounded-[6px] 
        h-[44px]
        w-[144px]
        font-normal
        hover:bg-transparent 
        
        ${mode === "dark" ? "border-neutral-700" : "border-neutral-400"} 
        ${mode === "dark" ? "text-neutral-50" : "text-neutral-900"} 
        ${mode === "dark" ? "hover:text-neutral-50" : "hover:text-neutral-900"} 
        ${mode === "dark" ? "dark:bg-transparent" : ""}
      `}
                >
                  {Number(sharpePoints) === 0
                    ? "--"
                    : `${numeral(Number(sharpePoints)).format("0.00a")}`}
                  &nbsp;
                  <span>PTS</span>
                </Button>
              </TooltipTrigger>
              <TooltipContent
                className={`border-neutral-800 mr-4 rounded-[4px] bg-transparent ${
                  mode === "dark" ? "border-neutral-800" : "border-neutral-200"
                } ${mode === "dark" ? "text-neutral-200" : "text-black"}`}
              >
                <p
                  className={`text-[12px] w-[260px] text-center leading-[15px]
               ${mode === "dark" ? "text-white" : "text-black"},`}
                >
                  It may take upto 2 hours for Sharpe points to be updated after
                  a successful transaction is completed.
                </p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </Box> */}
      </div>
    </div>
  );
}
