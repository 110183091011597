import "./App.css";
import MainBanner from "./components/banner/MainBanner";
import Header from "./components/Header";
import { useEffect, useState } from "react";

function App() {
  const [mode, setMode] = useState("dark");
  // const [walletAddress, setWalletAddress] = useState(null);

  // const backgroundImage =
  //   mode === "dark" ? "../Dark-bg.svg" : "../Light-bg.svg";

  const handleModeChange = (newMode) => {
    setMode(newMode);
  };

  // async function getConnectedWalletAddress() {
  //   if (window.ethereum) {
  //     try {
  //       // Request account access if needed
  //       // await window.ethereum.enable();
        
  //       // Get the connected wallet address
  //       const accounts = await window.ethereum.request({ method: 'eth_accounts' });
        
  //       if (accounts.length > 0) {
  //         // Return the first account address
  //         return accounts[0];
  //       } else {
  //         // No accounts connected
  //         console.error('No wallet accounts connected');
  //         return null;
  //       }
  //     } catch (error) {
  //       // Handle any errors that occur during the request
  //       console.error('Error getting wallet address:', error);
  //       return null;
  //     }
  //   } else {
  //     // The browser does not have the Ethereum provider
  //     console.error('No Ethereum provider detected');
  //     return null;
  //   }
  // }

  
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //   getConnectedWalletAddress().then((address) => {
  //     setWalletAddress(address);
  //   });
  //   }, 2000); // Call every 2 seconds

  //   // Clear interval on component unmount
  //   return () => clearInterval(interval);
  // }, [window.ethereum]);

  return (
    <>
     <MainBanner /> 
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        background: mode === "dark" ? '#000' : '#F6F6F6',

        // backgroundImage: `url(${backgroundImage})`,
        // backgroundRepeat: "no-repeat",
        // backgroundSize: "cover",
        flexDirection: "column",
        // gap: "30px",
        overflowY: "auto",
      }}
    >
      <Header onModeChange={handleModeChange}  />
      <iframe
        style={{
          // borderRadius: "4px",
          margin: "auto",
          maxWidth: "420px",
          outline: "none",
          border: "none",
          // border: "1px solid #58585f",
          boxShadow: "0px 8px 32px rgba(0, 0, 0, 0.08)",
          // borderRadius: "16px",
          // marginTop: "20px",
        }}
        src={`https://buy.onramper.com?apiKey=pk_prod_01J2XQWRXBYZPZDY99DEE87MFA&mode=buy,sell&themeName=${mode}&containerColor=${
          mode == "light" ? "ffffff" : "141416"
        }&primaryColor=${
          mode == "light" ? "98ecff" : "98ecff"
        }&secondaryColor=${mode == "light" ? "ffffff" : "3f3f43"}&cardColor=${
          mode == "light" ? "f6f7f9" : "272727"
        }&primaryTextColor=${
          mode == "light" ? "141519" : "#1d211e"
        }&secondaryTextColor=${
          mode == "light" ? "6b6f80" : "#1d211e"
        }&borderRadius=0&wgBorderRadius=0`}
        title="Onramper Widget"
        height="600px"
        width="416px"
        allow="accelerometer; autoplay; camera; gyroscope; payment"
      />
    </div>
    </>
  );
}

export default App;
